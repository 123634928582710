import React from 'react';
import ReactDOM from 'react-dom';
import Login from './login'

import './css/w3.css';
import './css/w3-2018.css';
import './css/bootstrap.min.css';


import {
  Container, Row, Col, Form, Input, Button, Navbar, Nav,
  NavbarBrand, NavLink, NavItem, UncontrolledDropdown,
  DropdownToggle, DropdownMenu, DropdownItem, CardImg, Header
} from 'reactstrap';

class Nav2 extends React.Component {
constructor(props) {
    super(props);
     
    this.handleClick = this.handleClick.bind(this);  
   }

handleClick() { 
alert('ok');   
    

   }
render() {
    return (

  <nav >

     <Row >
     
        
        
          
          <Col xs={{ order: 1 }} md={{ size: 7, offset: 1 }}>
     
             
            <h1>Hospital Management System</h1>
        <h3>Lelmolok Nursing Home</h3>
          </Col>
    <Col xs={{ order: 1 }} md={{ size: 3, offset: 1 }}>
     
             
            <Button color="primary" size="lg" type="button" className="login_button" onClick={this.handlelogin}>Login</Button>
          </Col>
          
        </Row>
    </nav>

);
}
}
export default Nav2;