import React from "react";
import axios from "axios";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, InputGroup, InputGroupText, InputGroupAddon, Input, Form, FormGroup, Row, Col} from 'reactstrap';
import "./css/w3.css"

class Bookpatient extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pno: '',
      fee: '',
      pmethod: 'Cash',
      pname: '',
      modal: false,
      pselect: false,
      nlist: [],
      companycode: 'H0001'


      };
    this.handlepno = this.handlepno.bind(this);
    this.handlefee = this.handlefee.bind(this);
    this.handlepmethod = this.handlepmethod.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handlefitlter = this.handlefitlter.bind(this);
    this.toggle = this.toggle.bind(this); 
  }
toggle(){
      this.setState({modal: !this.state.modal});
    }


  handlefee(event){
    this.setState({fee: event.target.value });
  }
  handlepmethod(event){
    this.setState({pmethod: event.target.value });
  }
  handlefitlter(event){
      if (event.target.value.length>0){
    this.setState({pselect: true });
   
    var jarray=[]
    jarray.push({pname: event.target.value});
      


    const mydata = JSON.stringify(jarray);
    

    //send data online
    const url = 'https://www.storepoaenterprise.com/hosi/patientnamelist.php';
    
    const msta=this;
   axios.post(url, mydata, {headers:{"Content-Type" : "application/json"}}).then(function(response) {
    //code here 
    if (response.data.length>0){
    msta.setState({nlist: response.data, pselect: true});
  }
  else
  {
    msta.setState({pselect: false})
  }
        
    })
   .catch(function (error) {
    // handle error
    alert(error)
  })
  .then(function () {
    // always executed
  });
    }

    else
    {
      this.setState({nlist: []})
    }
  }



  handlepno(event) { 
    this.setState({pno: event.target.value.trim() });
    const url = 'https://www.storepoaenterprise.com/hosi/patientname.php';
    var jarray=[]
    jarray.push({pno: event.target.value.trim(), companycode: this.state.companycode});


    const mydata = JSON.stringify(jarray);

    const ll=this;
   axios.post(url, mydata, {headers:{"Content-Type" : "application/json"}}).then(function(response) {
    //code here 
    ll.setState({pname: response.data[0]['pname']})     
   
    })
    .catch(function (error) {
      // handle error
      alert(error)
    })
    .then(function () {
      // always executed
    });
  }
   
     
     

     handleReset() {  
      this.setState({pname: '' });
    this.setState({pno: '' });
    this.setState({fee: '' });
    this.setState({pmethod: 'Cash' });
    
     }
  handleSave() {
    var jarray=[]
    
    //event.preventDefault();

   if (this.state.pno>0 ){

      jarray.push({pno: this.state.pno, fee: this.state.fee, pmethod: this.state.pmethod, companycode: this.state.companycode});
      


    const mydata = JSON.stringify(jarray);
    

    //send data online
     const url = 'https://www.storepoaenterprise.com/hosi/bookpatient.php';

    const ll=this;
   axios.post(url, mydata, {headers:{"Content-Type" : "application/json"}}).then(function(response) {
    //code here 
    
   alert("Patient Booked For Consultancy");     
   ll.handleReset()
    })
    .catch(function (error) {
      // handle error
      alert(error)
    })
    .then(function () {
      // always executed
    });
     }
     else
     {
      alert('Invalid Data Entry')
     }


    }


  render() {
    return (
      <div>
      <h1>Patient Booking fo Consultancy</h1>
      <Form >
        <FormGroup>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>Patient Number</InputGroupText>
            </InputGroupAddon>
             <Input onChange={this.handlepno} value={this.state.pno} />
          </InputGroup>
          </FormGroup>
        <FormGroup> 
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>Patient Name</InputGroupText>
            </InputGroupAddon>
             <Input value={this.state.pname} disabled/>
             
             
          </InputGroup>
          
          </FormGroup> 
          
          
          <FormGroup>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>Consultation Fee</InputGroupText>
            </InputGroupAddon>
             <Input onChange={this.handlefee} value={this.state.fee}/>
          </InputGroup>
          </FormGroup>
          <FormGroup>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>Payment Method</InputGroupText>
            </InputGroupAddon>
             <Input onChange={this.handlepmethod} onclick={this.handlepmethod} type="select">
              <option>Cash</option>
              <option>Mpesa</option>
              <option>Visa Card</option>
              <option>NHIF</option>
              <option>Insurance</option>
             </Input>
          </InputGroup>
          </FormGroup>
          
          
        <br/>  
        
        <Row>
        <Col ><Button color="primary" size="lg" type="button" onClick={this.handleSave} >Book Patient</Button></Col>
        <Col ><Button color="primary" size="lg" type="button"   onClick={this.toggle}>Find Patient</Button></Col>
        
      </Row>

      </Form>
      <Modal isOpen={this.state.modal} toggle={this.toggle} >
        <ModalHeader toggle={this.toggle}>Find Patient</ModalHeader>
        <ModalBody>
        <FormGroup>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>Patient Name</InputGroupText>
            </InputGroupAddon>
             <Input onChange={this.handlefitlter} type="text"/>
          </InputGroup>
          </FormGroup>
           {this.state.pselect && <FormGroup>
       <Input type="select" name="selectMulti" id="exampleSelectMulti" onClick={this.handleNlist} multiple>
       {this.state.nlist.map(item => (
          <option>{item.pname}</option>
          ))}
        </Input>
      </FormGroup> }
          </ModalBody>
        <ModalFooter>
          
        </ModalFooter>
      </Modal>
     
     </div>
    );
  }
}
export default Bookpatient