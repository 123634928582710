import React from "react";
import axios from "axios";
import { CSVLink } from 'react-csv'
import { Button, InputGroup, InputGroupText, InputGroupAddon, Input, Form, FormGroup, Label, Row, Col} from 'reactstrap';


class Stockcontrollist extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      companycode: 'H0001',
      plist: [],
      filename: 'lnhstock.csv'


      };

  }

  componentDidMount() {
    
var jarray=[]
    

      jarray.push({companycode: this.state.companycode});
      


    const mydata = JSON.stringify(jarray);
    

    //send data online
     const url = 'https://www.storepoaenterprise.com/hosi/stockcontrollist.php';

    const msta=this;
   axios.post(url, mydata, {headers:{"Content-Type" : "application/json"}}).then(function(response) {
    //code here 
    if (response.data.length>0){
   msta.setState({plist: response.data});
 }
      
    })
   .catch(function (error) {
    // handle error
    alert(error)
  })
  .then(function () {
    // always executed
  });
    }




  render() {
    return (
      <div>
      <h3>Stock List</h3>
      <Row>
      <Col>
      <Button color="primary" size="lg" type="button">
            <CSVLink  data={this.state.plist} filename={this.state.filename} class="w3-text-white">Export</CSVLink>
        </Button>
        </Col>
        </Row>
        <br/>
      <div class="w3-responsive">
             <table class="w3-table-all">
             <tr ><th>Product Code</th><th>Name</th><th>Quantty</th></tr>
          {this.state.plist.map(item => (
            <tr key={item.pcode}><td>{item.pcode}</td><td>{item.pname}</td><td>{item.qty}</td></tr>
          ))}
          
        </table>
        </div>
     
     </div>
    );
  }
}
export default Stockcontrollist