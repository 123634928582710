import React from "react";
import axios from "axios";
import { Button, InputGroup, InputGroupText, InputGroupAddon, Input, Form, FormGroup, Row, Col} from 'reactstrap';
import "./css/w3.css"

class Userreg extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      name: '',
      mobno: '',
      email: '',
      group: '',
      pwd: '',
      pwd1: '',
      status: 0,
      plist: [],
      usernamestatus: '',
      pwdstatus: '',
      companycode: 'H0001'


      };
    this.handleusername = this.handleusername.bind(this);
    this.handlename = this.handlename.bind(this);
    this.handlemobno = this.handlemobno.bind(this);
    this.handleemail = this.handleemail.bind(this);
    this.handlepwd= this.handlepwd.bind(this);
    this.handlepwd1 = this.handlepwd1.bind(this);
    this.handlegroup = this.handlegroup.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleuserlist = this.handleuserlist.bind(this);
  }


  handleusername(event) { 
    this.setState({username: event.target.value.trim() });
    //check if username exist 
    var jarray=[] 


        jarray.push({companycode: this.state.companycode, uname: event.target.value.trim()});
        const mydata = JSON.stringify(jarray);
        //send data online
        var mm=this;
           const url = 'https://www.storepoaenterprise.com/hosi/checkusername.php';

        
          
         axios.post(url, mydata,{headers:{"Content-Type" : "application/json"}}).then(function(response) {
          //code here 
          if (response.data.length>0){
          mm.setState({plist: response.data})
          mm.setState({status: response.data[0]['status']})

          if (response.data[0]['status']==1){
            //user exist
            mm.setState({usernamestatus: '** User name already in use **'})

          }
          else
          {
            mm.setState({usernamestatus: ''})
          }
        }
     
        })
         .catch(function (error) {
        // handle error
        alert(error)
      })
      .then(function () {
        // always executed
      });
    this.setState({username: event.target.value});

     }
      handlename(event) {  
    this.setState({name: event.target.value});

     }
     handlemobno(event) {  
    this.setState({mobno: event.target.value});

     }
     handleemail(event) {  
    this.setState({email: event.target.value });

     }
     handlegroup(event) { 
     this.setState({group: event.target.value });

     }
     handlepwd(event) {  
     this.setState({pwd: event.target.value });

     }
     handlepwd1(event) {  
     this.setState({pwd1: event.target.value });

     if (event.target.value != this.state.pwd){
      this.setState({pwdstatus: "Passsword not Matching"})
     }
     else{
      this.setState({pwdstatus: ""})
     }

     }
     handleuserlist(event) { 
        var jarray=[] 


        jarray.push({companycode: this.state.companycode});
        const mydata = JSON.stringify(jarray);
        //send data online
        var mm=this;
           const url = 'https://www.storepoaenterprise.com/hosi/userlist.php';

        
          
         axios.post(url, mydata,{headers:{"Content-Type" : "application/json"}}).then(function(response) {
          //code here 
          if (response.data.length>0){
          mm.setState({plist: response.data})
        }
     
        })
         .catch(function (error) {
        // handle error
        alert(error)
      })
      .then(function () {
        // always executed
      });


     }
     handlecompanycode(event) {  
    this.setState({companycode: event.target.value.trim() });

     }

     handleReset() {  
    this.setState({username: '' });
    this.setState({mobno: '' });
    this.setState({email: '' });
    this.setState({group: '' });
    this.setState({pwd: '' });
    this.setState({pwd1: '' });
    this.setState({statu: 0});
    this.setState({plist: []});
    this.setState({usernamestatus: ''});

     }
  handleSave() {
    var jarray=[]
    
    //event.preventDefault();

   if (this.state.username.length>2 && this.state.name.length>2 && this.state.email.length>2 &&  this.state.pwd.length>2  && this.state.pwdstatus.length<1 && (this.state.pwd==this.state.pwd1)){

      jarray.push({username: this.state.username, name: this.state.name, email: this.state.email, pwd: this.state.pwd, group: this.state.group, mobno: this.state.mobno, companycode: this.state.companycode});
      


    const mydata = JSON.stringify(jarray);
    

    //send data online
     const url = 'https://www.storepoaenterprise.com/hosi/saveuser.php';

    const ll=this;
   axios.post(url, mydata, {headers:{"Content-Type" : "application/json"}}).then(function(response) {
    //code here 
    
   alert("User Added");     
   ll.handleReset()
    })
    .catch(function (error) {
      // handle error
      alert(error)
    })
    .then(function () {
      // always executed
    });
     }
     else
     {
      alert('Invalid Data Entry')
     }


    }


  render() {
    return (
      <div>
      <h1>User Registration</h1>
      <Form >
        <FormGroup> 
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>User Name</InputGroupText>
            </InputGroupAddon>
             <Input onChange={this.handleusername} value={this.state.username}/>
             
             
          </InputGroup>
          <label ClassName="w3-text-red">{this.state.usernamestatus}</label>
          </FormGroup> 
          
          <FormGroup>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>Name</InputGroupText>
            </InputGroupAddon>
             <Input onChange={this.handlename} value={this.state.name}/>
          </InputGroup>
          </FormGroup>
          <FormGroup>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>Mobile No.</InputGroupText>
            </InputGroupAddon>
             <Input onChange={this.handlemobno} value={this.state.mobno}/>
          </InputGroup>
          </FormGroup>
          <FormGroup>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>Email</InputGroupText>
            </InputGroupAddon>
             <Input onChange={this.handleemail} value={this.state.email} type="email"/>
          </InputGroup>
          </FormGroup>
          <FormGroup>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>Group</InputGroupText>
            </InputGroupAddon>
             <Input type="select" name="select" id="exampleSelect" onClick={this.handlegroup} onChange={this.handlegroup}  value={this.setState.group}>
          <option>ADMIN</option>
          <option>PHARMACY</option>
          <option>DOCTOR</option>
          <option>LAB</option>
          <option>ACCOUNTS</option>
          <option>RECEPTION</option>
        </Input>
          </InputGroup>
        </FormGroup> 
        <FormGroup>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>Passsword</InputGroupText>
            </InputGroupAddon>
             <Input onChange={this.handlepwd} value={this.state.pwd} type="Password"/>
          </InputGroup>
          </FormGroup>
          <FormGroup>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>Confirm Passsword</InputGroupText>
            </InputGroupAddon>
             <Input onChange={this.handlepwd1} value={this.state.pwd1} type="Password"/>
          </InputGroup>
          <label ClassName="w3-text-red">{this.state.pwdstatus}</label>
          </FormGroup>
        <br/>  
        
        <Row>
        <Col ><Button color="primary" size="lg" type="button" onClick={this.handleSave} >Add User</Button></Col>
        <Col ><Button color="primary" size="lg" type="button"  onClick={this.handleuserlist}>User List</Button></Col>
        
      </Row>

      </Form>
      
     <div class="w3-responsive">
             <table class="w3-table-all">
             <tr ><th>User Name</th><th>Name</th><th>Mobile No.</th><th>Email</th></tr>
          {this.state.plist.map(item => (
            <tr key={item.username}><td>{item.username}</td><td>{item.name}</td><td>{item.mobno}</td><td>{item.email}</td></tr>
          ))}
         
        </table>
        </div>
     </div>
    );
  }
}
export default Userreg 