import React from "react";
import ReactDOM from 'react-dom';
import { Container, Row, Col, Button } from 'reactstrap';
import Changepwd from './changepwd'


class Loggedin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      
      statu:1


      };
    this.handlecpwd=this.handlecpwd.bind(this)
  }

handlecpwd(){
  ReactDOM.render(
  <React.StrictMode>
    <Changepwd uname={this.props.uname}/>
  </React.StrictMode>,
  document.getElementById('mbody')
);

}
 


  render() {
    return (
      
      <Container>
      <Row><Col>
      <Button color="primary" size="lg" type="button" className="login_button" onClick={this.handlecpwd}>Welcome - {this.props.uname}</Button>
      </Col>
      
      </Row>
      </Container>
      
    
    );
  }
}
export default Loggedin