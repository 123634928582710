import React from "react";
import ReactDOM from 'react-dom';
import axios from "axios";
import Prescribe from "./prescribe"
import Booklab from "./booklab"
import { Button, InputGroup, InputGroupText, InputGroupAddon, Input, Form, FormGroup, Row, Col} from 'reactstrap';
import "./css/w3.css"

class Savetest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pno: '',
      pname: '',
      sno: this.props.sno,
      test:'',
      cost: 0,
      pmethod: 'Cash',
      companycode: 'H0001'


      };
    this.handlepno = this.handlepno.bind(this);
    this.handletest = this.handletest.bind(this);
    this.handlepmethod = this.handlepmethod.bind(this);
    this.handleSave = this.handleSave.bind(this); 
    this.handlecost = this.handlecost.bind(this);
  }
  componentDidMount() {
    const url = 'https://www.storepoaenterprise.com/hosi/labpatientsno.php';
    var jarray=[]
    jarray.push({sno: this.props.sno, companycode: this.state.companycode});


    const mydata = JSON.stringify(jarray);

    const ll=this;
   axios.post(url, mydata, {headers:{"Content-Type" : "application/json"}}).then(function(response) {
    //code here 
    ll.setState({pname: response.data[0]['pname']})   
    ll.setState({pno: response.data[0]['pno']})   
    })
    .catch(function (error) {
      // handle error
      alert(error)
    })
    .then(function () {
      // always executed
    });

    }
    handlepno(event){
     
    }
    handletest(event){
     this.setState({test: event.target.value });
    }

  handlecost(event){
    this.setState({cost: event.target.value });
  }
  handlepmethod(event){
    this.setState({pmethod: event.target.value });
  }


  

     handleReset() {  
      this.setState({pname: '' });
    this.setState({pno: '' });
    this.setState({cost: 0, test: '' });
    this.setState({pmethod: 'Cash' });
    
     }
  handleSave() {
    var jarray=[]
    
    //event.preventDefault();

   if (this.state.pno>0 ){

      jarray.push({sno: this.props.sno, cost: this.state.cost, pmethod: this.state.pmethod, test: this.state.test, companycode: this.state.companycode});
      


    const mydata = JSON.stringify(jarray);
    

    //send data online
     const url = 'https://www.storepoaenterprise.com/hosi/savetest.php';

    const ll=this;
   axios.post(url, mydata, {headers:{"Content-Type" : "application/json"}}).then(function(response) {
    //code here 
    
   alert("Patient Lab Test Results Updated");     
   ll.handleReset()
    })
    .catch(function (error) {
      // handle error
      alert(error)
    })
    .then(function () {
      // always executed
    });
     }
     else
     {
      alert('Invalid Data Entry')
     }


    }


  render() {
    return (
      <div>
      <h1>Patient Consultancy</h1>
      <Form >
      <FormGroup> 
      
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>Patient Number</InputGroupText>
            </InputGroupAddon>
             <Input value={this.state.pno} disabled/>
          </InputGroup>
          </FormGroup>
        <FormGroup> 
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>Patient Name</InputGroupText>
            </InputGroupAddon>
             <Input value={this.state.pname} disabled/>
             
             
          </InputGroup>
          
          </FormGroup> 
          <FormGroup> 
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>Test Results</InputGroupText>
            </InputGroupAddon>
             <Input value={this.state.test} onChange ={this.handletest}/>
             
             
          </InputGroup>
          
          </FormGroup> 
          <FormGroup> 
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>Cost</InputGroupText>
            </InputGroupAddon>
             <Input value={this.state.cost} onChange ={this.handlecost}/>
             
             
          </InputGroup>
          
          </FormGroup> 
          
          <FormGroup>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>Payment Method</InputGroupText>
            </InputGroupAddon>
             <Input onChange={this.handlepmethod} onclick={this.handlepmethod} type="select">
              <option>Cash</option>
              <option>Mpesa</option>
              <option>Visa Card</option>
              <option>NHIF</option>
              <option>Insurance</option>
             </Input>
          </InputGroup>
          </FormGroup>
          
          
          
        <br/>  
        
        <Row>
        <Col ><Button color="primary" size="lg" type="button" onClick={this.handleSave} >Save</Button></Col>
        
      </Row>
      <div id="pres"></div>
      </Form>
      
     
     </div>
    );
  }
}
export default Savetest