import React from "react";
import axios from "axios";
import { Button, InputGroup, InputGroupText, InputGroupAddon, Input, Form, FormGroup, Label, Row, Col} from 'reactstrap';


class Stockcontrol extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      prodname: '',
      description: '',
      pcode: '',
      qyt: 0,
      cstock: 0,
      companycode: 'H0001',
      plist: [],
      date1: '10/09/2021',
      bprice: 0,
      sprice: 0


      };


    this.handleprodname = this.handleprodname.bind(this);
    this.handledescription = this.handledescription.bind(this);
    this.handlepcode = this.handlepcode.bind(this);
    this.handleqyt = this.handleqyt.bind(this);
    this.handledate1 = this.handledate1.bind(this);
    
    this.handlecompanycode= this.handlecompanycode.bind(this);
    this.handlecstock= this.handlecstock.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleReset = this.handleReset.bind(this);

    this.handlebprice = this.handlebprice.bind(this);
    this.handlesprice = this.handlesprice.bind(this);
  }

  componentDidMount() {
    

  }


  handleprodname(event) {  
    this.setState({prodname: event.target.value.trim() });

     }
      handledescription(event) {  
    this.setState({description: event.target.value.trim() });

     }
     handledate1(event) {  
    this.setState({date1: event.target.value.trim() });

     }
     handlepcode(event) {  
      var p=event.target.value.trim()
      p=p.toUpperCase();
    this.setState({pcode: p });
    var jarray=[]
    jarray.push({pcode: p, companycode: this.state.companycode});
      


    const mydata = JSON.stringify(jarray);
    

    //send data online
    const url = 'https://www.storepoaenterprise.com/hosi/prodname.php';
    
    const msta=this;
   axios.post(url, mydata).then(function(response) {
    //code here 
   msta.setState({prodname: response.data[0]['pname'],bprice: response.data[0]['bprice'],sprice: response.data[0]['sprice'], cstock: response.data[0]['stock']});
    

     
    })
   .catch(function (error) {
    // handle error
    alert(error)
    })
    .then(function () {
    // always executed
    });

     }
     handleqyt(event) {  
    this.setState({qyt: event.target.value });

     }
     handlebprice(event) {  
     this.setState({bprice: event.target.value });

     }
     handlesprice(event) {  
      this.setState({sprice: event.target.value });
 
      }
     handlecompanycode(event) {  
    this.setState({companycode: event.target.value.trim() });

     }
     handlecstock(event) {  
     this.setState({cstock: event.target.value });

     }

     handleReset() {  
    this.setState({prodname: '' });
    this.setState({description: '' });
    this.setState({pcode: '' });
    this.setState({qyt: 0 });
    this.setState({cstock: 0 });
    this.setState({companycode: '' });

     }
  handleSubmit(event) {
    var jarray=[]
    
    event.preventDefault();

   if ((this.state.pcode.length)>2 && this.state.qyt>=0 ){

      jarray.push({pcode: this.state.pcode, qty: this.state.qyt, user1: 'user1', companycode: this.state.companycode, bprice:this.state.bprice, sprice:this.state.sprice,  date1: this.state.date1});
      


    const mydata = JSON.stringify(jarray);

    //send data online
     const url = 'https://www.storepoaenterprise.com/hosi/stockcontrol.php';
     
    //const msta=this;
   axios.post(url, mydata,{headers:{"Content-Type" : "application/json"}}).then(function(response) {
    //code here 
    
    alert("Stockcontrol Updated");
       //reset states    
    })
   .catch(function (error) {
    // handle error
    alert(error)
  })
  .then(function () {
    // always executed
  });
    }
    else
    {
      alert("Invalid data entry")
    }

    }


  render() {
    return (
      <div>
      <h1>Stock Control</h1>
      <Form onSubmit={this.handleSubmit}>
      <Row><Col>
        <FormGroup> 
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>Product Code</InputGroupText>
            </InputGroupAddon>
             <Input onChange={this.handlepcode} value={this.state.pcode}/>
          </InputGroup> 
          </FormGroup>
          </Col><Col>
          <FormGroup>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>Product Name</InputGroupText>
            </InputGroupAddon>
            <Input  value={this.state.prodname} disabled/>
             
          </InputGroup>
          </FormGroup>
          </Col></Row><Row><Col>
          <FormGroup>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>Quantity: Current - {this.state.cstock}</InputGroupText>
            </InputGroupAddon>
             <Input onChange={this.handleqyt} value={this.state.qyt}/>
          </InputGroup>
          </FormGroup>
          </Col><Col>
          <FormGroup>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>Date</InputGroupText>
            </InputGroupAddon>
             <Input onChange={this.handledate1} value={this.state.date1} type="date"/>
          </InputGroup>
          </FormGroup>
          </Col></Row>

          <Row><Col>
          <FormGroup>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>Current Buying Price</InputGroupText>
            </InputGroupAddon>
             <Input onChange={this.handlebprice} value={this.state.bprice}/>
          </InputGroup>
          </FormGroup>
          </Col><Col>
          <FormGroup>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>Current Selling Price </InputGroupText>
            </InputGroupAddon>
             <Input onChange={this.handlesprice} value={this.state.sprice}/>
          </InputGroup>
          </FormGroup>
          </Col></Row>
          
          
        <br/>  
        <Button color="primary" size="lg" type="button" onClick={this.handleSubmit}>Add Stock</Button>
      </Form>
      
     
     </div>
    );
  }
}
export default Stockcontrol 