import React,  { useRef } from "react";
import axios from "axios";
import ReactDOM from 'react-dom';
import { Col, Button, InputGroup, InputGroupText, InputGroupAddon, Input, Form, FormGroup} from 'reactstrap';
import './login.css';
import Loggedin from './loggedin';
import Menu from './menu';
import Nav1 from './nav1';
import Video from './video';
import Changepwd from './changepwd';

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      uname: '',
      pwd: '',
      statu: 0,
      list: []
      };

  this.handleUname = this.handleUname.bind(this);
  this.handlePwd = this.handlePwd.bind(this);
  this.handleSubmit = this.handleSubmit.bind(this);
  this.handleReset = this.handleReset.bind(this);

  }


  handleUname(event) {  
    this.setState({uname: event.target.value.trim() });

     }
      handlePwd(event) {  
    this.setState({pwd: event.target.value.trim() });

     }
     

     handleReset() {  
    this.setState({Uname: '' });
    this.setState({pwd: '' });
    

     }
  handleSubmit(event) {

    var jarray=[]
    
    //event.preventDefault();

   

      jarray.push({uname: this.state.uname, pwd: this.state.pwd});
      


    const mydata = JSON.stringify(jarray);
    
 const md=this;
    //send data online
     const url = 'https://www.storepoaenterprise.com/hosi/login.php';

    
   axios.post(url, mydata, {headers:{"Content-Type" : "application/json"}}).then(function(response) {
    md.setState({list: response.data});
    //code here 
    if (((md.state.uname=='user1' && md.state.pwd=='hos1298') || response.data[0]['status']==1) && response.data[0]['status1']==1 ){
     md.setState({groupn: response.data[0]['groupn']}) 
   ReactDOM.render(
  <React.StrictMode>
    <Loggedin uname={md.state.uname}/>
  </React.StrictMode>,
  document.getElementById('mmm')
);

ReactDOM.render(
  <React.StrictMode>
    <Nav1 uname={response.data}/>
  </React.StrictMode>,
  document.getElementById('nav')
);


    }
    else
    {
      //load change Password

      
      ReactDOM.render(
  <React.StrictMode>
    <Changepwd uname={md.state.uname}/>
  </React.StrictMode>,
  document.getElementById('mbody')
);

ReactDOM.render(
  <React.StrictMode>
    <Loggedin uname={md.state.uname}/>
  </React.StrictMode>,
  document.getElementById('mmm')
);

    }


     
})
.catch(function (error) {
    // handle error
    alert(error)
  })
  .then(function () {
    // always executed
  });


    }


  render() {
    return (
      <div>
      <Form>
        <FormGroup row> 
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>User Name</InputGroupText>
            </InputGroupAddon>
            
             <Input onChange={this.handleUname} value={this.state.uname}/>
            
          </InputGroup> 

          </FormGroup> 
          <br/>
          <FormGroup> 
          <InputGroup>

            <InputGroupAddon addonType="prepend">
              <InputGroupText>Password</InputGroupText>
            </InputGroupAddon>
             <Input onChange={this.handlePwd} value={this.state.pwd} type="Password"/>
          </InputGroup>
          
        </FormGroup> 
        <br/>  
        
      </Form>
      <Button color="primary" size="lg" type="button" className="login_button" onClick={this.handleSubmit} className="login_button">Login</Button>
      </div>
    
    );
  }
}
export default Login