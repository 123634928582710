import React,  { useRef } from "react";
import axios from "axios";
import ReactDOM from 'react-dom';
import { Col, Button, InputGroup, InputGroupText, InputGroupAddon, Input, Form, FormGroup} from 'reactstrap';
import './login.css';
import Loggedin from './loggedin';
import Menu from './menu';
import Nav1 from './nav1';
import Video from './video';
import Intro from './intro'

class Changepwd extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      uname: props.uname,
      opwd: '',
      npwd: '',
      cpwd: '',
      statu: 0,
      pwdstatus: '',
      list: []
      };

  this.handleUname = this.handleUname.bind(this);
  this.handleOpwd = this.handleOpwd.bind(this);
  this.handleNpwd = this.handleNpwd.bind(this);
  this.handleCpwd = this.handleCpwd.bind(this);
  this.handleChangepwd = this.handleChangepwd.bind(this);
  this.handleReset = this.handleReset.bind(this);

  }


  handleUname(event) {  
    this.setState({uname: event.target.value.trim() });

     }
      handleOpwd(event) {  
    this.setState({opwd: event.target.value.trim() });

     }
     handleNpwd(event) {  
    this.setState({npwd: event.target.value.trim() });

     }
     handleCpwd(event) {  
    this.setState({cpwd: event.target.value.trim() });
    if (event.target.value != this.state.npwd){
      this.setState({pwdstatus: "Passsword not Matching"})
     }
     else{
      this.setState({pwdstatus: ""})
     }

     }
     

     handleReset() {  
    this.setState({Uname: '' , npwd: '', cpwd: '', statu:0, list: []});
    this.setState({opwd: '' });
    
    

     }
  handleChangepwd(event) {

    var jarray=[]
    
    //event.preventDefault();

   

      jarray.push({uname: this.state.uname, pwd: this.state.npwd, opwd: this.state.opwd});
      


    const mydata = JSON.stringify(jarray);
    
 const md=this;
    //send data online
     const url = 'https://www.storepoaenterprise.com/hosi/changepassword.php';
if(md.state.npwd==md.state.cpwd){
    
   axios.post(url, mydata, {headers:{"Content-Type" : "application/json"}}).then(function(response) {
    md.setState({list: response.data});
    //code here 
    if (response.data[0]['status']==1){
     
   ReactDOM.render(
  <React.StrictMode>
    <Loggedin uname={md.state.uname}/>
  </React.StrictMode>,
  document.getElementById('mmm')
);

ReactDOM.render(
  <React.StrictMode>
    <Nav1 uname={response.data}/>
  </React.StrictMode>,
  document.getElementById('nav')
);

ReactDOM.render(
  <React.StrictMode>
    <Intro />
  </React.StrictMode>,
  document.getElementById('mbody')
);

    
}

     
})

.catch(function (error) {
    // handle error
    alert(error)
  })
  .then(function () {
    // always executed
  });
}

    }


  render() {
    return (
      <div>
      <h3>Reset Password</h3>
      <Form>
        <FormGroup row> 
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>User Name</InputGroupText>
            </InputGroupAddon>
            
             <Input  value={this.state.uname} disabled/>
            
          </InputGroup> 

          </FormGroup> 
          <br/>
          <FormGroup> 
          <InputGroup>

            <InputGroupAddon addonType="prepend">
              <InputGroupText>Old Password</InputGroupText>
            </InputGroupAddon>
             <Input onChange={this.handleOpwd} value={this.state.opwd} type="Password"/>
          </InputGroup>
          
        </FormGroup> 
        <FormGroup> 
          <InputGroup>

            <InputGroupAddon addonType="prepend">
              <InputGroupText>New Password</InputGroupText>
            </InputGroupAddon>
             <Input onChange={this.handleNpwd} value={this.state.npwd} type="Password"/>
          </InputGroup>
          
        </FormGroup> 
        <FormGroup> 
          <InputGroup>

            <InputGroupAddon addonType="prepend">
              <InputGroupText>Confirm Password</InputGroupText>
            </InputGroupAddon>
             <Input onChange={this.handleCpwd} value={this.state.cpwd} type="Password"/>
          </InputGroup>
          <label ClassName="w3-text-red">{this.state.pwdstatus}</label>
        </FormGroup> 
        <br/>  
        
      </Form>
      <Button color="primary" size="lg" type="button" className="login_button" onClick={this.handleChangepwd} className="login_button">Update Password</Button>
      </div>
    
    );
  }
}
export default Changepwd