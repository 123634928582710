import React from "react";
import ReactDOM from 'react-dom';
import axios from "axios";
import './css/modal.css';
import ModalExample from './ModalExample';
import Outpatientlist from './outpatientlist'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, InputGroup, InputGroupText, InputGroupAddon, Input, Form, FormGroup, Label, Row, Col} from 'reactstrap';


class Prescribe extends React.Component {
  constructor(props) {
    super(props);
    var today = new Date();
    var date2 = (today.getMonth() + 1) + '/' +  today.getDate() + '/' + today.getFullYear();
    this.state = {
      prodname: '',
      description: '',
      pcode: '',
      qyt: 1,
      cstock: 0,
      sprice: 0,
      companycode: 'H0001',
      plist: [],
      tcost: 0,
      date1: date2,
      modal: false,
      modal1: true,
      modal2: false,
      pselect: false,
      pay: 0,
      tchange: 0,
      daynumber:1,
      daytimes:1,
      qdose: 1,
      pm: 'Cash',
      cno: this.props.sno,
      finish: false,
      nlist: [],
      rlist: [],


      };


    this.handleprodname = this.handleprodname.bind(this);
    this.handledescription = this.handledescription.bind(this);
    this.handlepcode = this.handlepcode.bind(this);
    this.handleqyt = this.handleqyt.bind(this);
    this.handledate1 = this.handledate1.bind(this);
    this.handlesprice = this.handlesprice.bind(this);
    this.handlecompanycode= this.handlecompanycode.bind(this);
    this.handlecstock= this.handlecstock.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.handleReset1 = this.handleReset1.bind(this);
    this.handlePlist = this.handlePlist.bind(this);
    this.handleNlist = this.handleNlist.bind(this);
    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.toggle = this.toggle.bind(this);
    this.showModal1 = this.showModal1.bind(this);
    this.hideModal1 = this.hideModal1.bind(this);
    this.toggle1 = this.toggle1.bind(this);
    this.handlepay = this.handlepay.bind(this);
    this.handleDaynumber = this.handleDaynumber.bind(this);
    this.handleDaytimes = this.handleDaytimes.bind(this);
    this.handleQdose = this.handleQdose.bind(this);
    this.handleNextpatient = this.handleNextpatient.bind(this);
    this.paymethod = this.paymethod.bind(this);
    this.sleep= this.sleep.bind(this);
  }

  

  componentDidMount() {
var today = new Date();
    var date2 = (today.getMonth() + 1) + '/' +  today.getDate() + '/' + today.getFullYear();
    this.setState({date1: date2})

    const url = 'https://www.storepoaenterprise.com/hosi/labresults.php';
    var jarray=[]
    jarray.push({sno: this.props.sno, companycode: this.state.companycode});

    const mydata = JSON.stringify(jarray);

    const ll=this;
   axios.post(url, mydata, {headers:{"Content-Type" : "application/json"}}).then(function(response) {
    //code here 
    ll.setState({rlist: response.data});  
    })
    .catch(function (error) {
      // handle error
      alert(error)
    })
    .then(function () {
      // always executed
    });
    
  
    
}

 getDate() {

    var date = new Date();
    var month = date .getMonth() + 1;
    var day = date .getDate();
    var year = date .getFullYear();
    date= month + "/" + day + "/" + year;
    
    this.setState({
      date1: date
    });
  }
  sleep (sleepDuration){
    var now = new Date().getTime();
    while(new Date().getTime() < now + sleepDuration){ /* Do nothing */ }
}
  
  paymethod(event){
    this.setState({pm: event.target.value.trim()});

  }
  handleNlist(event){
    this.setState({prodname: event.target.value, pselect: false});
     var jarray=[]
    jarray.push({prodname: event.target.value});
      var y=0
      var pc=""
      for(var x=0;x<event.target.value.length;x++){
        if (y==1)
          pc += event.target.value[x]


        if (event.target.value[x]==":")
          y=1


      }
this.setState({pcode: pc})
      var jarray=[]
    jarray.push({pcode: pc});
      


    const mydata = JSON.stringify(jarray);
    

    //send data online
    const url = 'https://www.storepoaenterprise.com/hosi/prodname.php';
    
    const msta=this;
   axios.post(url, mydata, {headers:{"Content-Type" : "application/json"}}).then(function(response) {
    //code here 
    
    msta.setState({prodname: response.data[0]['pname'], sprice: response.data[0]['sprice']});
        
    })
   .catch(function (error) {
    // handle error
    alert(error)
  })
  .then(function () {
    // always executed
  });



  }

  handlepay(event) { 
  var ch=0; 
    if (event.target.value.trim()>this.state.tcost){
      ch=event.target.value.trim()-this.state.tcost;
    }

    this.setState({pay: event.target.value.trim(), tchange: ch});

     }
  hideModal(event) {  
    this.setState({modal: false});

     }
     showModal(event) {  
    this.setState({modal: true});

     }
     handleNextpatient(){

      alert(this.state.cno)
     }
     toggle(){
      if ((this.state.plist.length)>0 ){
      this.setState({modal: !this.state.modal, finish: false});
    }
    else{
      alert("Prescription List empty")
     }
    }
    hideModal1(event) {  
    this.setState({modal1: 'display-none'});

     }

     showModal1(event) {  
      if ( (this.state.tcost<=this.state.pay) && this.state.finish==false ){
      var jarray=[]

     jarray.push({pcode: this.state.pm, qty: 0, sprice: this.state.tcost, companycode: this.state.companycode});
      //alert(this.state.plist.length)
    this.setState({plist: this.state.plist.concat(jarray)});
    this.setState({modal1: false, modal2: true, finish: true});
  }
  else
  {
    alert('Invalid data entry')
  }

     }
     toggle1(){
      if ((this.state.plist.length)>0 ){
      this.setState({modal1: !this.state.modal1});
    }
    else{
      alert("Prescription List empty")
     }
    }

    
  handleprodname(event) {  
    this.setState({prodname: event.target.value.trim(), pselect: true });
   
    var jarray=[]
    jarray.push({prodname: event.target.value.trim()});
      


    const mydata = JSON.stringify(jarray);
    

    //send data online
    const url = 'https://www.storepoaenterprise.com/hosi/prodnamelist.php';
    
    const msta=this;
   axios.post(url, mydata, {headers:{"Content-Type" : "application/json"}}).then(function(response) {
    //code here 
    
    msta.setState({nlist: response.data});
        
    })
   .catch(function (error) {
    // handle error
    alert(error)
  })
  .then(function () {
    // always executed
  });

     }
      handledescription(event) {  
    this.setState({description: event.target.value.trim() });

     }
     handledate1(event) {  
    this.setState({date1: event.target.value.trim() });

     }
     handlesprice(event) {  
    this.setState({sprice: event.target.value.trim() });

     }
     handlePlist(event) {  
      if ((this.state.pcode.length)>2 && this.state.qyt>0 && this.state.sprice>0 ){
      var jarray=[]

      jarray.push({pcode: this.state.pcode, qty: this.state.qyt, sprice: this.state.sprice, nday: this.state.daynumber, ntimes: this.state.daytimes, qdose: this.state.qdose, cno: this.state.cno, companycode: this.state.companycode});
      var ccost=this.state.tcost;
      ccost=ccost + (this.state.qyt * this.state.sprice);
      this.setState({plist: this.state.plist.concat(jarray), tcost: ccost});
      this.handleReset()

      }
      else
      {
        alert('Invalid Data Entry')
      }

     }
     handlepcode(event) {  
    this.setState({pcode: event.target.value.trim() });
    
    var jarray=[]
    jarray.push({pcode: event.target.value.trim()});
      


    const mydata = JSON.stringify(jarray);
    

    //send data online
    const url = 'https://www.storepoaenterprise.com/hosi/prodname.php';
    
    const msta=this;
   axios.post(url, mydata, {headers:{"Content-Type" : "application/json"}}).then(function(response) {
    //code here 
    
    msta.setState({prodname: response.data[0]['pname'], sprice: response.data[0]['sprice']});
        
    })
   .catch(function (error) {
    // handle error
    alert(error)
  })
  .then(function () {
    // always executed
  });

     }

     handleDaytimes(event) {  
      var td= event.target.value * this.state.daynumber * this.state.qdose;
      
    this.setState({daytimes: event.target.value, qyt:td});

     }

     handleDaynumber(event) {  
      var td= event.target.value * this.state.daytimes * this.state.qdose;
      
    this.setState({daynumber: event.target.value, qyt:td });

     }
     handleQdose(event){
       var td= event.target.value * this.state.daytimes * this.state.daynumber;
      
    this.setState({qdose: event.target.value, qyt:td });



     }

     handleqyt(event) {  
      var tprice=event.target.value * this.state.sprice;
      
    this.setState({qyt: event.target.value });

     }
     handlebprice(event) {  
     this.setState({bprice: event.target.value });

     }
     handlecompanycode(event) {  
    this.setState({companycode: event.target.value.trim() });

     }
     handlecstock(event) {  
     this.setState({cstock: event.target.value });

     }

     handleReset() {  
    this.setState({prodname: '' });
    this.setState({description: '' });
    this.setState({pcode: '' });
    this.setState({sprice: 0 , nday: 1, ntimes: 1, qdose: 1});
    this.setState({qyt: 1 });
    this.setState({cstock: 0 });
    this.setState({companycode: '', finish: false, modal1: true, modal2: false });
    //this.setState({plist: [] });
    this.setState({tcost: 0, modal: false, pay: 0, tchange: 0, pm: 'Cash', modal1: 'display-none', modal2: 'display-block'});

     }
      handleReset1() {  
    this.setState({prodname: '' });
    this.setState({description: '' });
    this.setState({pcode: '' });
    this.setState({sprice: 0 , nday: 1, ntimes: 1, qdose: 1});
    this.setState({qyt: 1 });
    this.setState({cstock: 0 });
    this.setState({companycode: '', finish: false, modal1: true, modal2: false });
    //this.setState({plist: [] });
    this.setState({tcost: 0, modal: false, pay: 0, tchange: 0, pm: 'Cash', modal1: 'display-none', modal2: 'display-block'});

     }

  handleSubmit(event) {
    
    if ((this.state.plist.length)>0 ){
   //this.sleep(5000);
    
    this.setState({modal: false})

    const mydata = JSON.stringify(this.state.plist);
    this.handleReset1()
    //send data online
     const url = 'https://www.storepoaenterprise.com/hosi/consult.php';
     var mm=this;
    //const msta=this;
   axios.post(url, mydata, {headers:{"Content-Type" : "application/json"}}).then(function(response) {
    //code here 
    
   alert("Prescription Updated ");
   ReactDOM.render(
      <React.StrictMode>
        <Outpatientlist />
      </React.StrictMode>,
      document.getElementById('mbody')
    );
    
  })
  .catch(function (error) {
    // handle error
    alert(error)
  })
  .then(function () {
    // always executed
  });


 }
 else
 {
  alert('Error: ')
 }


    }


  render() {
    return (
      <div>
      <h1>Prescription</h1>

      <Form onSubmit={this.handleSubmit}>
      <Row><Col>
        <FormGroup> 
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>Product Code</InputGroupText>
            </InputGroupAddon>
             <Input onChange={this.handlepcode} value={this.state.pcode}/>
          </InputGroup> 
          </FormGroup>
          </Col><Col>
          <FormGroup>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>Product Name</InputGroupText>
            </InputGroupAddon>
            <Input onChange={this.handleprodname}value={this.state.prodname} />
            
             
          </InputGroup>
          </FormGroup>
          {this.state.pselect && <FormGroup>
       <Input type="select" name="selectMulti" id="exampleSelectMulti" onClick={this.handleNlist} multiple>
       {this.state.nlist.map(item => (
          <option>{item.pname}</option>
          ))}
        </Input>
      </FormGroup> }
          </Col><Col>
          <FormGroup>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>Unit price</InputGroupText>
            </InputGroupAddon>
            <Input value={this.state.sprice}  disabled/>
             
          </InputGroup>
          </FormGroup>
          </Col></Row>
          <Row><Col>
            <FormGroup>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>Number of Days</InputGroupText>
            </InputGroupAddon>
            <Input onChange={this.handleDaynumber} value={this.state.daynumber} />
            
             
          </InputGroup>
          </FormGroup>
          </Col>
          <Col>
            <FormGroup>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>Times in a Day</InputGroupText>
            </InputGroupAddon>
            <Input onChange={this.handleDaytimes} value={this.state.daytimes} />
            
             
          </InputGroup>
          </FormGroup>
          </Col>
          <Col>
           <FormGroup>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>Quantity Per Dose</InputGroupText>
            </InputGroupAddon>
            <Input onChange={this.handleQdose} value={this.state.qdose} />
            
             
          </InputGroup>
          </FormGroup>
          </Col>
          </Row>

          <Row><Col>
          <FormGroup>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>Quantity</InputGroupText>
            </InputGroupAddon>
             <Input onChange={this.handleqyt} value={this.state.qyt}/>
          </InputGroup>
          </FormGroup>

          </Col><Col>
          <FormGroup>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>Date</InputGroupText>
            </InputGroupAddon>
             <Input onChange={this.handledate1} value={this.state.date1} type="date"/>
          </InputGroup>
          </FormGroup>
          </Col></Row>
          
         <Row>
        <Col ><Button color="primary" size="lg" type="button" onClick={this.handlePlist}>Add Drug</Button></Col>
        <Col ><Button color="primary" size="lg" type="button" onClick={this.handleSubmit} >Next Patient</Button></Col>
        
      </Row>
        

      </Form>
      <h3>Prescription List</h3>
      <div class="w3-responsive">
             <table class="w3-table-all">
             <tr ><th>Product Code</th><th>Quantity</th><th>Unit Price</th><th> Total Cost</th></tr>
          {this.state.plist.map(item => (
            <tr key={item.pcode}><td>{item.pcode}</td><td>{item.qdose}/{item.ntimes} = {item.qty}</td><td>{item.sprice}</td><td>{item.sprice * item.qty}</td></tr>
          ))}
          <tr ><th>Grand Total</th><th></th><th></th><th> {this.state.tcost}</th></tr>
        </table>
        </div>

        <h3>Lab Test results</h3>
      <div class="w3-responsive">
             <table class="w3-table-all">
             <tr ><th>sno</th><th>Cno</th><th>Test</th><th> Results</th></tr>
          {this.state.rlist.map(item => (
            <tr key={item.sno}><td>{item.sno}</td><td>{item.pno}</td><td>{item.test}</td><td>{item.result}</td></tr>
          ))}
          
        </table>
        </div>
     </div>
    );
  }
}
export default Prescribe