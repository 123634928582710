import React from "react";
import axios from "axios";
import { CSVLink } from 'react-csv'
import { Button, InputGroup, InputGroupText, InputGroupAddon, Input, Form, FormGroup, Row, Col} from 'reactstrap';

import './css/modal.css';
import './css/w3.css'

class Productreg extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      prodname: '',
      description: '',
      pcode: '',
      sprice: 0,
      bprice:0,
      companycode: 'H0001',
      plist: [],
      filename: 'lnhproducts.csv'


      };
    this.handleprodname = this.handleprodname.bind(this);
    this.handledescription = this.handledescription.bind(this);
    this.handlepcode = this.handlepcode.bind(this);
    this.handlesprice = this.handlesprice.bind(this);
    this.handlebprice= this.handlebprice.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.handleplist = this.handleplist.bind(this);
  }


  handleprodname(event) {  
    this.setState({prodname: event.target.value.trim() });

     }
      handledescription(event) {  
    this.setState({description: event.target.value.trim() });

     }
     handlepcode(event) {  
      var p=event.target.value.trim()
      p=p.toUpperCase();
    this.setState({pcode: p});

     }
     handlesprice(event) {  
    this.setState({sprice: event.target.value });

     }
     handlebprice(event) {  
     this.setState({bprice: event.target.value });

     }
     handleplist(event) { 
        var jarray=[] 


        jarray.push({companycode: this.state.companycode});
        const mydata = JSON.stringify(jarray);
        //send data online
        var mm=this;
           const url = 'https://www.storepoaenterprise.com/hosi/prodlist.php';

        
          
         axios.post(url, mydata,{headers:{"Content-Type" : "application/json"}}).then(function(response) {
          //code here 
          
          if (response.data.length>0){
          mm.setState({plist: response.data})
        }
     
        })
         .catch(function (error) {
        // handle error
        alert("Data empty")
      })
      .then(function () {
        // always executed
      });


     }
     handlecompanycode(event) {  
    this.setState({companycode: event.target.value.trim() });

     }

     handleReset() {  
    this.setState({prodname: '' });
    this.setState({description: '' });
    this.setState({pcode: '' });
    this.setState({sprice: 0 });
    this.setState({bprice: 0 });
    

     }
  handleSubmit(event) {
    var jarray=[]
    
    //event.preventDefault();

   if ((this.state.pcode.length)>2 && this.state.sprice>0 && this.state.bprice>0 &&  (this.state.description.length)>2 && (this.state.prodname.length)>2){

      jarray.push({prodname: this.state.prodname, pcode: this.state.pcode, description: this.state.description, sprice: this.state.sprice, bprice: this.state.bprice, companycode: this.state.companycode});
      


    const mydata = JSON.stringify(jarray);
    

    //send data online
     const url = 'https://www.storepoaenterprise.com/hosi/saveprod.php';

    const ll=this;
   axios.post(url, mydata, {headers:{"Content-Type" : "application/json"}}).then(function(response) {
    //code here 
    
   alert("Product saved");     
   ll.handleReset()
    })
    .catch(function (error) {
      // handle error
      alert(error)
    })
    .then(function () {
      // always executed
    });
     }
     else
     {
      alert('Invalid Data Entry')
     }


    }


  render() {
    return (
      <div>
      <h1>Drugs Registration</h1>
      <Form onSubmit={this.handleSubmit}>
        <FormGroup> 
          <InputGroup>
            <InputGroupAddon addonType="prepend" >
              <InputGroupText className="lwidth">Drug Name</InputGroupText>
            </InputGroupAddon>
             <Input onChange={this.handleprodname} value={this.state.prodname}/>
          </InputGroup>
          </FormGroup> 
          <FormGroup>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText className="lwidth">Description</InputGroupText>
            </InputGroupAddon>
             <Input onChange={this.handledescription} value={this.state.description}/>
          </InputGroup>
          </FormGroup>
          <FormGroup>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText className="lwidth">Drug Code</InputGroupText>
            </InputGroupAddon>
             <Input onChange={this.handlepcode} value={this.state.pcode}/>
          </InputGroup>
          </FormGroup>
          <FormGroup>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText className="lwidth">Buying Price</InputGroupText>
            </InputGroupAddon>
             <Input onChange={this.handlebprice} value={this.state.bprice}/>
          </InputGroup>
          </FormGroup>
          <FormGroup>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText className="lwidth">Selling price</InputGroupText>
            </InputGroupAddon>
             <Input onChange={this.handlesprice} value={this.state.sprice}/>
          </InputGroup>
        </FormGroup> 
        <br/>  
        
        <Row >
        <Col ><Button color="primary" size="lg" type="button" onClick={this.handleSubmit} >Add Product</Button></Col>
        <Col ><Button color="primary" size="lg" type="button"  onClick={this.handleplist}>Product List</Button></Col>
        <Col>
        <Button color="primary" size="lg" type="button">
            <CSVLink  data={this.state.plist} filename={this.state.filename} class="w3-text-white">Export</CSVLink>
        </Button>
        </Col>

        
      </Row>

      </Form>
     <div class="w3-responsive w3-margin">
             <table class="w3-table-all">
             <tr ><th>Product Code</th><th>Name</th><th>Description</th><th> Buying price</th><th>Selling price</th></tr>
          {this.state.plist.map(item => (
            <tr key={item.pcode}><td>{item.pcode}</td><td>{item.pname}</td><td>{item.description}</td><td>{item.bprice}</td><td>{item.sprice}</td></tr>
          ))}
         
        </table>
        </div>
     </div>
    );
  }
}
export default Productreg 