import React from "react";
class Video extends React.Component {
  
  getVideo = elem => {
    this.video = elem
  }

  playVideo = () => {
    this.video.play()
  };

  pauseVideo = () => {
    this.video.pause();
  };

  render = () => {
    return (
      <div>
        <video
          ref={this.getVideo}
          src="http://techslides.com/demos/sample-videos/small.mp4"
          type="video/mp4"
        />

        <div>
          <button onClick={this.playVideo}>
            Play!
          </button>
          <button onClick={this.pauseVideo}>
            Pause!
          </button>
        </div>
      </div>
    );
  };
}

export default Video;