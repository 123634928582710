import React from "react";
import { Container, Row, Col, Button } from 'reactstrap';
import drug from './img/drugs.jpeg';


class Intro extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      
      statu:1


      };
    }
   


  render() {
    return (
      
      <Container>
       <img src={drug} width="100%" />
      <p>Hospital management system (HMS) is a computer system that helps to automate and  manage the information related to health care and aids in the job completion of health care providers effectively. Our HMS manages data related to all departments of healthcare centre</p>
      <p>HMS solves the complications coming from managing all the paper works of every patient associated with the various departments of hospitalization with confidentiality. HMS provides the ability to manage all the paperwork in one place, reducing the work of staff in arranging and analyzing the paperwork of the patients.</p> 
      <p>It helps to outline and implement policies, guarantee communication and coordination between employees, automate routine tasks, design the patient-oriented workflows, advertise services, manage human and financial resources and provide the uninterrupted supply chain.</p>
      </Container>
      
    
    );
  }
}
export default Intro