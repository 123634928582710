import React from "react";
import axios from "axios";
import './css/modal.css';
import ModalExample from './ModalExample';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, InputGroup, InputGroupText, InputGroupAddon, Input, Form, FormGroup, Label, Row, Col} from 'reactstrap';


class Sales extends React.Component {
  constructor(props) {
    super(props);
    var today = new Date();
    var date2 = (today.getMonth() + 1) + '/' +  today.getDate() + '/' + today.getFullYear();
    this.state = {
      prodname: '',
      description: '',
      pcode: '',
      qyt: 0,
      cstock: 0,
      sprice: 0,
      companycode: 'H0001',
      plist: [],
      tcost: 0,
      date1: date2,
      modal: false,
      modal1: true,
      modal2: false,
      modalb:false,
      pselect: false,
      pay: 0,
      tchange: 0,
      pm: 'Cash',
      finish: false,
      cno: 0,
      nlist: []


      };


    this.handleprodname = this.handleprodname.bind(this);
    this.handledescription = this.handledescription.bind(this);
    this.handlepcode = this.handlepcode.bind(this);
    this.handleqyt = this.handleqyt.bind(this);
    this.handledate1 = this.handledate1.bind(this);
    this.handlesprice = this.handlesprice.bind(this);
    this.handlecompanycode= this.handlecompanycode.bind(this);
    this.handlecstock= this.handlecstock.bind(this);
    this.handlecno = this.handlecno.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.handlePlist = this.handlePlist.bind(this);
    this.handleNlist = this.handleNlist.bind(this);
    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.toggle = this.toggle.bind(this);
    this.toggle1 = this.toggle1.bind(this);
    this.showModal1 = this.showModal1.bind(this);
    this.hideModal1 = this.hideModal1.bind(this);
    this.toggle1 = this.toggle1.bind(this);
    this.handlepay = this.handlepay.bind(this);
    this.paymethod = this.paymethod.bind(this);
    this.sleep= this.sleep.bind(this);
    this.loadlist= this.loadlist.bind(this);
  }

  

  componentDidMount() {
var today = new Date();
    var date2 = (today.getMonth() + 1) + '/' +  today.getDate() + '/' + today.getFullYear();
    this.setState({date1: date2})
    
}

 getDate() {

    var date = new Date();
    var month = date .getMonth() + 1;
    var day = date .getDate();
    var year = date .getFullYear();
    date= month + "/" + day + "/" + year;
    
    this.setState({
      date1: date
    });
  }
  sleep (sleepDuration){
    var now = new Date().getTime();
    while(new Date().getTime() < now + sleepDuration){ /* Do nothing */ }
}
loadlist(){

  var jarray=[]
    jarray.push({cno: this.state.cno, companycode: this.state.companycode});

      


    const mydata = JSON.stringify(jarray);
    

    //send data online
    const url = 'https://www.storepoaenterprise.com/hosi/listconsult.php';
    
    const msta=this;
   axios.post(url, mydata, {headers:{"Content-Type" : "application/json"}}).then(function(response) {
    //code here 
    
    var y=response.data.length
    for (var i = 0; i < y; i++) {
      var jarray=[]
      var tqy=response.data[i]['nday'] * response.data[i]['ntimes'] * response.data[i]['qdose']
      var ccost=msta.state.tcost;
      ccost=ccost + (tqy * response.data[i]['sprice']);
     jarray.push({pcode: response.data[i]['pcode'], qty: tqy, sprice: response.data[i]['sprice'], companycode: "H0001"});
      //alert(this.state.plist.length)
    msta.setState({plist: msta.state.plist.concat(jarray), tcost: ccost});
    }
        
    })
   .catch(function (error) {
    // handle error
    alert(error)
  })
  .then(function () {
    // always executed
  });
}
  handlecno(event){
    this.setState({cno: event.target.value.trim()});
  }
  paymethod(event){
    this.setState({pm: event.target.value.trim()});

  }
  handleNlist(event){
    this.setState({prodname: event.target.value, pselect: false});
     var jarray=[]
    jarray.push({prodname: event.target.value});

      


    const mydata = JSON.stringify(jarray);
    

    //send data online
    const url = 'https://www.storepoaenterprise.com/hosi/prodcode.php';
    
    const msta=this;
   axios.post(url, mydata, {headers:{"Content-Type" : "application/json"}}).then(function(response) {
    //code here 
    
    msta.setState({sprice: response.data[0]['sprice'], pcode: response.data[0]['pcode']});
        
    })
   .catch(function (error) {
    // handle error
    alert(error)
  })
  .then(function () {
    // always executed
  });

  }

  handlepay(event) { 
  var ch=0; 
    if (event.target.value.trim()>this.state.tcost){
      ch=event.target.value.trim()-this.state.tcost;
    }

    this.setState({pay: event.target.value.trim(), tchange: ch});

     }
  hideModal(event) {  
    this.setState({modal: false});

     }
     showModal(event) {  
    this.setState({modal: true});

     }
     toggle(){
      if ((this.state.plist.length)>0 ){
      this.setState({modal: !this.state.modal, finish: false});
    }
    else{
      alert("Prescription List empty")
     }
    }
    toggle1(){
      this.setState({modalb: !this.state.modalb})
    
    }
    hideModal1(event) {  
    this.setState({modal1: 'display-none'});

     }
     showModal1(event) {  
      if ( (this.state.tcost<=this.state.pay) && this.state.finish==false ){
      var jarray=[]

     jarray.push({pcode: this.state.pm, qty: 0, sprice: this.state.tcost, companycode: this.state.companycode});
      //alert(this.state.plist.length)
    this.setState({plist: this.state.plist.concat(jarray)});
    this.setState({modal1: false, modal2: true, finish: true});
  }
  else
  {
    alert('Invalid data entry')
  }

     }
    
    
  handleprodname(event) {  
    this.setState({prodname: event.target.value.trim(), pselect: true });
   
    var jarray=[]
    jarray.push({prodname: event.target.value});
      


    const mydata = JSON.stringify(jarray);
    

    //send data online
    const url = 'https://www.storepoaenterprise.com/hosi/prodnamelist.php';
    
    const msta=this;
   axios.post(url, mydata, {headers:{"Content-Type" : "application/json"}}).then(function(response) {
    //code here 
    
    msta.setState({nlist: response.data});
        
    })
   .catch(function (error) {
    // handle error
    alert(error)
  })
  .then(function () {
    // always executed
  });

     }
      handledescription(event) {  
    this.setState({description: event.target.value.trim() });

     }
     handledate1(event) {  
    this.setState({date1: event.target.value.trim() });

     }
     handlesprice(event) {  
    this.setState({sprice: event.target.value.trim() });

     }
     handlePlist(event) {  
      if ((this.state.pcode.length)>2 && this.state.qyt>0 && this.state.sprice>0 ){
      var jarray=[]

      jarray.push({pcode: this.state.pcode, qty: this.state.qyt, sprice: this.state.sprice, companycode: this.state.companycode});
      var ccost=this.state.tcost;
      ccost=ccost + (this.state.qyt * this.state.sprice);
      this.setState({plist: this.state.plist.concat(jarray), tcost: ccost});
      }
      else
      {
        alert('Invalid Data Entry')
      }

     }
     handlepcode(event) {  
      var p=event.target.value.trim()
      p=p.toUpperCase();
    this.setState({pcode:  p});
    
    var jarray=[]
    jarray.push({pcode: p, companycode: this.state.companycode});
      


    const mydata = JSON.stringify(jarray);
    

    //send data online
    const url = 'https://www.storepoaenterprise.com/hosi/prodname.php';
    
    const msta=this;
   axios.post(url, mydata, {headers:{"Content-Type" : "application/json"}}).then(function(response) {
    //code here 
    
    msta.setState({prodname: response.data[0]['pname'], sprice: response.data[0]['sprice'], cstock: response.data[0]['stock']});
        
    })
   .catch(function (error) {
    // handle error
    alert(error)
  })
  .then(function () {
    // always executed
  });

     }
     handleqyt(event) {  
      var tprice=event.target.value * this.state.sprice;
      
    this.setState({qyt: event.target.value });

     }
     handlebprice(event) {  
     this.setState({bprice: event.target.value });

     }
     handlecompanycode(event) {  
    this.setState({companycode: event.target.value.trim() });

     }
     handlecstock(event) {  
     this.setState({cstock: event.target.value });

     }

     handleReset() {  
    this.setState({prodname: '' });
    this.setState({description: '' });
    this.setState({pcode: '' });
    this.setState({sprice: 0 });
    this.setState({qyt: 0 });
    this.setState({cstock: 0 });
    this.setState({companycode: '', finish: false, modal1: true, modal2: false });
    this.setState({plist: [] });
    this.setState({tcost: 0, modal: false,modalb: false, pay: 0, tchange: 0, pm: 'Cash', modal1: 'display-none', modal2: 'display-block'});

     }

  handleSubmit(event) {
    
    if ((this.state.plist.length)>0 && (this.state.tcost<=this.state.pay) && this.state.finish==true  ){
   //this.sleep(5000);
    
    this.setState({modal: false})

    const mydata = JSON.stringify(this.state.plist);
    this.handleReset()
    //send data online
     const url = 'https://www.storepoaenterprise.com/hosi/prescribe.php';
     var mm=this;
    //const msta=this;
   axios.post(url, mydata, {headers:{"Content-Type" : "application/json"}}).then(function(response) {
    //code here 
    
   alert("Prescription Processed ");
    
  })
  .catch(function (error) {
    // handle error
    alert(error)
  })
  .then(function () {
    // always executed
  });


 }
 else
 {
  alert('Error: Invalid amount or click confirm Payment first')
 }


    }


  render() {
    return (
      <div>
      <h1>Pharmacy Sales</h1>

      <Form onSubmit={this.handleSubmit}>
      <Row><Col>
        <FormGroup> 
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>Product Code</InputGroupText>
            </InputGroupAddon>
             <Input onChange={this.handlepcode} value={this.state.pcode}/>
          </InputGroup> 
          </FormGroup>
          </Col><Col>
          <FormGroup>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>Product Name</InputGroupText>
            </InputGroupAddon>
            <Input onChange={this.handleprodname}value={this.state.prodname} />
            
             
          </InputGroup>
          </FormGroup>
          {this.state.pselect && <FormGroup>
       <Input type="select" name="selectMulti" id="exampleSelectMulti" onClick={this.handleNlist} multiple>
       {this.state.nlist.map(item => (
          <option>{item.pname}</option>
          ))}
        </Input>
      </FormGroup> }
          </Col><Col>
          <FormGroup>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>Unit price</InputGroupText>
            </InputGroupAddon>
            <Input value={this.state.sprice}  disabled/>
             
          </InputGroup>
          </FormGroup>
          </Col></Row><Row><Col>
          <FormGroup>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>Quantity Curren stock: {this.state.cstock}</InputGroupText>
            </InputGroupAddon>
             <Input onChange={this.handleqyt} value={this.state.qyt}/>
          </InputGroup>
          </FormGroup>

          </Col><Col>
          <FormGroup>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>Date</InputGroupText>
            </InputGroupAddon>
             <Input onChange={this.handledate1} value={this.state.date1} type="date"/>
          </InputGroup>
          </FormGroup>
          </Col></Row>
          
         <Row>
        <Col ><Button color="primary" size="lg" type="button" onClick={this.handlePlist}>Add Drug</Button></Col>
        <Col ><Button color="primary" size="lg" type="button" onClick={this.toggle1} >Load Prescription</Button></Col>
        <Col ><Button color="primary" size="lg" type="button" onClick={this.toggle} >Process Prescription</Button></Col>
        
      </Row>
        <div>
      <Modal isOpen={this.state.modal} toggle={this.toggle} >
        <ModalHeader toggle={this.toggle}>Payment Processing</ModalHeader>
        <ModalBody>
        <FormGroup>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>Total Cost</InputGroupText>
            </InputGroupAddon>
             <Input value={this.state.tcost} type="number" disabled/>
          </InputGroup>
          </FormGroup>
          <FormGroup>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>Amount Paid</InputGroupText>
            </InputGroupAddon>
             <Input onChange={this.handlepay} value={this.state.pay} type="number"/>
          </InputGroup>
          </FormGroup>
          <FormGroup>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>Change</InputGroupText>
            </InputGroupAddon>
             <Input value={this.state.tchange} type="number" disabled/>
          </InputGroup>
          </FormGroup>
           <FormGroup>
        <Label >Payment Method</Label>
        <Input type="select" name="select" id="exampleSelect" onChange={this.paymethod}>
          <option>Cash</option>
          <option>Mpesa</option>
          <option>Visa Card</option>
          <option>NHIF</option>
          <option>Insurance</option>
        </Input>
      </FormGroup>
        </ModalBody>
        <ModalFooter>
        {this.state.modal1 && <Button color="primary" onClick={this.showModal1}>Confirm Payment</Button>}
         {this.state.modal2 && <Button color="primary" onClick={this.handleSubmit}>Finish</Button>}
          <Button color="secondary" onClick={this.handleReset}>Cancel</Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={this.state.modalb} toggle={this.toggle1} >
        <ModalHeader toggle={this.toggle1}>Load Prescription</ModalHeader>
        <ModalBody>
        <FormGroup>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>Consultancy No.</InputGroupText>
            </InputGroupAddon>
             <Input onChange={this.handlecno} type="number"/>
          </InputGroup>
          </FormGroup>
          </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={this.loadlist}>Load</Button>
          <Button color="secondary" onClick={this.handleReset}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </div>

      </Form>
      <h3>Prescription List</h3>
      <div class="w3-responsive">
             <table class="w3-table-all">
             <tr ><th>Product Code</th><th>Quantity</th><th>Unit Price</th><th> Total Cost</th></tr>
          {this.state.plist.map(item => (
            <tr key={item.pcode}><td>{item.pcode}</td><td>{item.qty}</td><td>{item.sprice}</td><td>{item.sprice * item.qty}</td></tr>
          ))}
          <tr ><th>Grand Total</th><th></th><th></th><th> {this.state.tcost}</th></tr>
        </table>
        </div>
     </div>
    );
  }
}
export default Sales 