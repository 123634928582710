import React from "react";
import axios from "axios";
import { Button, InputGroup, InputGroupText, InputGroupAddon, Input, Form, FormGroup, Row, Col} from 'reactstrap';
import "./css/w3.css"

class Manageuser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      groupn: '',
      status: 0,
      glist: [],
      ulist: [],
      companycode: 'H0001'


      };
    this.handleusername = this.handleusername.bind(this);
    this.handlegroup = this.handlegroup.bind(this);
    this.handleglist = this.handleglist.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleuserlist = this.handleuserlist.bind(this);
  }


    handlegroup(event){
      this.setState({groupn: event.target.value})
    }
    handleusername(event){
      this.setState({username: event.target.value})
    }
      
     handleuserlist(event) { 
        var jarray=[] 


        jarray.push({companycode: this.state.companycode});
        const mydata = JSON.stringify(jarray);
        //send data online
        var mm=this;
           const url = 'https://www.storepoaenterprise.com/hosi/userlist.php';

        
          
         axios.post(url, mydata,{headers:{"Content-Type" : "application/json"}}).then(function(response) {
          //code here 
          if (response.data.length>0){
          mm.setState({plist: response.data})
        }
     
        })
         .catch(function (error) {
        // handle error
        alert(error)
      })
      .then(function () {
        // always executed
      });


     }
     handlecompanycode(event) {  
    this.setState({companycode: event.target.value.trim() });

     }

     handleReset() {  
    this.setState({username: '' });
    this.setState({mobno: '' });
    this.setState({email: '' });
    this.setState({group: '' });
    this.setState({pwd: '' });
    this.setState({pwd1: '' });
    this.setState({statu: 0});
    this.setState({plist: []});
    this.setState({usernamestatus: ''});

     }
  handleSave() {
    var jarray=[]
    
    //event.preventDefault();

   if (this.state.username.length>2 && this.state.name.length>2 && this.state.email.length>2 &&  this.state.pwd.length>2  && this.state.pwdstatus.length<1 && (this.state.pwd==this.state.pwd1)){

      jarray.push({username: this.state.username, name: this.state.name, email: this.state.email, pwd: this.state.pwd, group: this.state.group, mobno: this.state.mobno, companycode: this.state.companycode});
      


    const mydata = JSON.stringify(jarray);
    

    //send data online
     const url = 'https://www.storepoaenterprise.com/hosi/saveusergroup.php';

    const ll=this;
   axios.post(url, mydata, {headers:{"Content-Type" : "application/json"}}).then(function(response) {
    //code here 
    
   alert("User Added");     
   ll.handleReset()
    })
    .catch(function (error) {
      // handle error
      alert(error)
    })
    .then(function () {
      // always executed
    });
     }
     else
     {
      alert('Invalid Data Entry')
     }


    }


  render() {
    return (
      <div>
      <h1>User Registration</h1>
      <Form >
        <FormGroup> 
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>User Name</InputGroupText>
            </InputGroupAddon>
             <Input type="select" name="select" id="exampleSelect" onClick={this.handleuser} onChange={this.handleuser}  value={this.setState.username}>
          {this.state.ulist.map(item => (
          <option>{item.username}</option>
          ))}
             </Input>
             
          </InputGroup>
          
          </FormGroup> 
          
          
          <FormGroup>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>Group</InputGroupText>
            </InputGroupAddon>
             <Input type="select" name="select" id="exampleSelect" onClick={this.handlegroup} onChange={this.handlegroup}  value={this.setState.groupn}>
          
          <option>ADMIN</option>
          <option>PHARMACY</option>
          <option>DOCTOR</option>
          <option>LAB</option>
          <option>ACCOUNTS</option>
        </Input>
          </InputGroup>
        </FormGroup> 
        
        <br/>  
        
        <Row>
        <Col ><Button color="primary" size="lg" type="button" onClick={this.handleSave} >Add Group to user</Button></Col>
        
        
      </Row>

      </Form>
      
     <div class="w3-responsive">
             <table class="w3-table-all">
             <tr ><th>User Name</th><th>Group</th></tr>
          {this.state.glist.map(item => (
            <tr key={item.username}><td>{item.groupn}</td></tr>
          ))}
         
        </table>
        </div>
     </div>
    );
  }
}
export default Manageuser