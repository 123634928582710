import React from "react";
import axios from "axios";
import { CSVLink } from 'react-csv'
import {Modal, ModalHeader, ModalBody, ModalFooter, Button, InputGroup, InputGroupText, InputGroupAddon, Input, Form, FormGroup, Label, Row, Col} from 'reactstrap';


import './css/w3.css'

class Saleslist extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      companycode: 'H0001',
      plist: [],
      totals: 0,
      date2: '',
      paymethod: 'All',
      filtertype: '',
      fdip1: false,
      pcode: '',
      modal: false,
      modal1: true,
      modal2: false,
      filename: 'lnhsales.csv'


      };

       this.toggle = this.toggle.bind(this);
       this.pfilter = this.pfilter.bind(this);
       this.handledate = this.handledate.bind(this);
       this.handlefilter= this.handlefilter.bind(this);
       this.handlepcode= this.handlepcode.bind(this);
  }
   toggle(){
      this.setState({modal: !this.state.modal});
    }
    handlepcode(event){
      this.setState({pcode: event.target.value });
    }
    handlefilter(event){
       this.setState({filtertype: event.target.value });
       if (event.target.value=='Product code'){
         this.setState({fdip1: true });
       }

    }
    pfilter(){
      this.setState({modal: !this.state.modal, plist: []});
      
      this.setState({totals: 0});
      var jarray=[]
    

      jarray.push({companycode: this.state.companycode, date1: this.state.date2, pcode: this.state.pcode, paymethod: this.state.paymethod});
      


    const mydata = JSON.stringify(jarray);
    

    //send data online
     const url = 'https://www.storepoaenterprise.com/hosi/saleslistfilter.php';
 
    const msta=this;
   axios.post(url, mydata, {headers:{"Content-Type" : "application/json"}}).then(function(response) {
    var tl=0;

    //code here 
   
   
    if (response.data.length>0){
      for(var x=0; x<(response.data.length);x++){
    tl=tl+response.data[x]['totals']
   }
   msta.setState({plist: response.data, totals: tl});
 }
      
    })
   .catch(function (error) {
    // handle error
    alert(error)
  })
  .then(function () {
    // always executed
  });

    }
    handledate(event){
      this.setState({date2: event.target.value });

    }

  componentDidMount() {
    
var jarray=[]
    

      jarray.push({companycode: this.state.companycode});
      


    const mydata = JSON.stringify(jarray);
    

    //send data online
     const url = 'https://www.storepoaenterprise.com/hosi/saleslist.php';
 
    const msta=this;
   axios.post(url, mydata, {headers:{"Content-Type" : "application/json"}}).then(function(response) {
    var tl=0;

    //code here 
   
   
    if (response.data.length>0){
      for(var x=0; x<(response.data.length);x++){
    tl=tl+response.data[x]['totals']
   }
   msta.setState({plist: response.data, totals: tl});
 }
      
    })
   .catch(function (error) {
    // handle error
    alert(error)
  })
  .then(function () {
    // always executed
  });
    }




  render() {
    return (
      <div>
      <h3>Pharmacy Sales</h3>
      <Row>
      <Col>
      <Button color="primary" size="lg" type="button" onClick={this.toggle} >Filter</Button>
      </Col>
      <Col>
       <Button color="primary" size="lg" type="button">
            <CSVLink  data={this.state.plist} filename={this.state.filename} class="w3-text-white">Export</CSVLink>
        </Button>

        </Col>
        </Row>
        <br/>
      <div class="w3-responsive">
             <table class="w3-table-all">
             <tr ><th>Sno.</th><th>Date</th><th>Prescription no</th><th>Product Code</th><th>Description</th><th>Quantity</th><th>Unit Price</th><th>Totals</th><th>Pay Method</th></tr>
          {this.state.plist.map(item => (
            <tr key={item.pno}><td>{item.sno}</td><td>{item.date1}</td><td>{item.pno}</td><td>{item.pcode}</td><td>{item.pname}</td><td class="w3-right-align">{item.qty}</td><td class="w3-right-align">{item.sprice}</td><td class="w3-right-align">{item.totals}</td><td class="w3-right-align">{item.pmethod}</td></tr>
          ))}
          <tr ><th></th><th></th><th>Totals</th><th></th><th></th><th></th><th></th><th class="w3-right-align">{this.state.totals}</th></tr>
        </table>
        </div>


         <div>
      <Modal isOpen={this.state.modal} toggle={this.toggle} >
        <ModalHeader toggle={this.toggle}>Filter Sales</ModalHeader>
        <ModalBody>
          <FormGroup>
           <InputGroup>
        <InputGroupAddon addonType="prepend">
              <InputGroupText>Filter By</InputGroupText>
            </InputGroupAddon>
        <Input type="select" name="select" id="exampleSelect" onChange={this.handlefilter}>
          <option>Date</option>
          <option>Product code</option>
          <option>Month</option>
          <option>Year</option>
        </Input>
        </InputGroup>
      </FormGroup>
       <FormGroup>
        <Label >Payment Method</Label>
        <Input type="select" name="select" id="exampleSelect" onChange={this.paymethod}>
          <option>All</option>
          <option>Cash</option>
          <option>Mpesa</option>
          <option>Visa Card</option>
          <option>NHIF</option>
          <option>Insurance</option>
        </Input>
      </FormGroup>
     {this.state.fdip1 && <FormGroup>
           <InputGroup>
        <InputGroupAddon addonType="prepend">
              <InputGroupText>Product code</InputGroupText>
        </InputGroupAddon>
          <Input value={this.state.pcode} type="text" onChange={this.handlepcode}/>
        </InputGroup>
      </FormGroup> }
          <FormGroup>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>Date</InputGroupText>
            </InputGroupAddon>
             <Input value={this.state.date2} type="date" onChange={this.handledate}/>
          </InputGroup>
          </FormGroup>
           
        </ModalBody>
        <ModalFooter>
        <Button color="primary" onClick={this.pfilter}>Process Filter</Button>
         
        </ModalFooter>
      </Modal>
    </div>
      
     
     </div>
    );
  }
}
export default Saleslist