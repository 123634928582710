import React from 'react';
import ReactDOM from 'react-dom';

import Menu from './menu';
import './css/w3.css';
import './css/w3-2018.css';
import './css/bootstrap.min.css';


import {
  Container, Row, Col, Form, Input, Button, Navbar, Nav,
  NavbarBrand, NavLink, NavItem, UncontrolledDropdown,
  DropdownToggle, DropdownMenu, DropdownItem, CardImg, Header
} from 'reactstrap';

class Nav1 extends React.Component {
constructor(props) {
    super(props);
     
    this.handleClick = this.handleClick.bind(this);  
   }

handleClick() { 
  
    

   }
render() {
    return (

  <nav className="navbar" >

    <Row >
		 
        
          <Col xs={{ order: 0 }} md={{ size:1, offset: 2 }}>
             
            <Menu groupn={this.props.uname[0]['groupn']}/>
	    
          </Col>
     
		</Row>
 	 
    </nav>

);
}
}
export default Nav1;