
import React from "react";
import ReactDOM from 'react-dom';
import Login from './login'
import { Container, Row, Col, Button } from 'reactstrap';

import './css/w3.css';
import './css/w3-2018.css';
import './css/bootstrap.min.css';

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      
      statu: 0


      };
    this.handlelogin = this.handlelogin.bind(this);
    
  }

  handlelogin(event) {  
     ReactDOM.render(
  <React.StrictMode>
    <Login />
  </React.StrictMode>,
  document.getElementById('mmm')
);

     }
  render() {
  return (


    <Container>
    <Row>
        
        <Col xs={{ order: 0 }} md={{ size: 7, offset: 1 }}><h1>Hospital Management System</h1>
        <h3>Lelmolok Nursing Home</h3>
        </Col>
        <Col xs={{ order: 1 }} md={{ size: 3, offset: 1 }} id="mmm"><Button color="primary" size="lg" type="button" className="login_button" onClick={this.handlelogin}>Login</Button></Col>
      </Row>
      
      
    </Container>
  );
}
}

export default Header;