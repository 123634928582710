import React from "react";
import ReactDOM from 'react-dom';
import axios from "axios";
import Outpatientlist from './outpatientlist'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, InputGroup, InputGroupText, InputGroupAddon, Input, Form, FormGroup, Row, Col} from 'reactstrap';
import "./css/w3.css"

class Booklab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cno: this.props.sno,
      test: '',
      rlist: [],
      companycode: 'H0001'


      };
    this.handletest = this.handletest.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleclose = this.handleclose.bind(this);
   
  }
  componentDidMount() {

   

  }
 handleclose(){
  alert('clos')
 }


  handletest(event){
    this.setState({test: event.target.value });
  }
  
     

     handleReset() {  
      this.setState({test: '' });
    
    
     }
  handleSave() {
    var jarray=[]
    
    //event.preventDefault();

   if (this.props.sno>0 ){

      jarray.push({pno: this.props.sno, test: this.state.test, companycode: this.state.companycode});
      


    const mydata = JSON.stringify(jarray);
    

    //send data online
     const url = 'https://www.storepoaenterprise.com/hosi/labtest.php';

    const ll=this;
   axios.post(url, mydata, {headers:{"Content-Type" : "application/json"}}).then(function(response) {
    //code here 
    
   alert("Patient Booked For labtest");     
   ll.handleReset()
    })
    .catch(function (error) {
      // handle error
      alert(error)
    })
    .then(function () {
      // always executed
      ReactDOM.render(
      <React.StrictMode>
        <Outpatientlist />
      </React.StrictMode>,
      document.getElementById('mbody')
    );
    });
     }
     else
     {
      alert('Invalid Data Entry')
     }


    }


  render() {
    return (
      <div>
      <h1>Patient Booking fo Lab Test</h1>
      <Form >
        
        <FormGroup> 
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>Lab Test</InputGroupText>
            </InputGroupAddon>
             <Input value={this.state.test} onChange={this.handletest}/>
             
             
          </InputGroup>
          
          </FormGroup> 
          
          
          
        <br/>  
        
        <Row>
        <Col ><Button color="primary" size="lg" type="button" onClick={this.handleSave} >Book Patient</Button></Col>
        <Col ><Button color="primary" size="lg" type="button"   onClick={this.handleclose}>Close</Button></Col>
        
      </Row>

      </Form>
      
     
     </div>
    );
  }
}
export default Booklab