import Productreg from './components/prodreg'
import Header from './components/header'
import Menu from './components/menu'
import Intro from './components/intro'
import Nav2 from './components/nav2'
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <Header />
      </header>
      <nav className="App-nav" id="nav">
      </nav>
      <div id="mbody" className="App-body">
      <Intro />

      </div>
      <footer className="App-footer">
      
      </footer>
      
    </div>
  );
}

export default App;
