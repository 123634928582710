import React from "react";
import axios from "axios";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, InputGroup, InputGroupText, InputGroupAddon, Input, Form, FormGroup, Row, Col} from 'reactstrap';
import "./css/w3.css"

class Patientreg extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pname: '',
      pno: '',
      mobno: '',
      email: '',
      nhifno: '',
      idno: '',
      modal: false,
      pselect: false,
      nlist: [],
      companycode: 'H0001'


      };
    this.handlepname = this.handlepname.bind(this);
    this.handlepno = this.handlepno.bind(this);
    this.handlemobno = this.handlemobno.bind(this);
    this.handleemail = this.handleemail.bind(this);
    this.handlenhifno= this.handlenhifno.bind(this);
    this.handleidno = this.handleidno.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handlepno = this.handlepno.bind(this);
    this.toggle = this.toggle.bind(this); 
    this.handlefitlter = this.handlefitlter.bind(this);
  }
  toggle(){
      this.setState({modal: !this.state.modal});
    }

    handlefitlter(event){
      if (event.target.value.trim().length>0){

      this.setState({pname: event.target.value.trim(), pselect: true });
   
    var jarray=[]
    jarray.push({pname: event.target.value});
      


    const mydata = JSON.stringify(jarray);
    

    //send data online
    const url = 'https://www.storepoaenterprise.com/hosi/patientnamelist.php';
    
    const msta=this;
   axios.post(url, mydata, {headers:{"Content-Type" : "application/json"}}).then(function(response) {
    //code here 
    if (response.data.length>0){
    msta.setState({nlist: response.data, pselect: true});
  }
  else
  {
    msta.setState({pselect: false})
  }
        
    })
   .catch(function (error) {
    // handle error
    alert(error)
  })
  .then(function () {
    // always executed
  });
    }

    else
    {
      this.setState({nlist: []})
    }
  }


  handlepname(event) { 
    this.setState({pname: event.target.value });
    const url = 'https://www.storepoaenterprise.com/hosi/patientno.php';
    var jarray=[]
    jarray.push({companycode: this.state.companycode});


    const mydata = JSON.stringify(jarray);

    const ll=this;
   axios.post(url, mydata, {headers:{"Content-Type" : "application/json"}}).then(function(response) {
    //code here 
    ll.setState({pno: response.data[0]['pno']})     
   
    })
    .catch(function (error) {
      // handle error
      alert(error)
    })
    .then(function () {
      // always executed
    });
  }
   
      handlepno(event) {  
    this.setState({pno: event.target.value});

     }
     handlemobno(event) {  
    this.setState({mobno: event.target.value});

     }
     handleemail(event) {  
    this.setState({email: event.target.value });

     }
     handlenhifno(event) { 
     this.setState({nhifno: event.target.value });

     }
     handleidno(event) {  
     this.setState({idno: event.target.value });

     }
     
     handlecompanycode(event) {  
    this.setState({companycode: event.target.value.trim() });

     }

     handleReset() {  
    this.setState({pname: '' });
    this.setState({mobno: '' });
    this.setState({email: '' });
    this.setState({pno: '' });
    this.setState({nhifno: '' });
    this.setState({idno: '' });

     }
  handleSave() {
    var jarray=[]
    
    //event.preventDefault();

   if (this.state.pname.length>2  && this.state.mobno.length>2){

      jarray.push({pname: this.state.pname, pno: this.state.pno, email: this.state.email, mobno: this.state.mobno, companycode: this.state.companycode});
      


    const mydata = JSON.stringify(jarray);
    

    //send data online
     const url = 'https://www.storepoaenterprise.com/hosi/savepatient.php';

    const ll=this;
   axios.post(url, mydata, {headers:{"Content-Type" : "application/json"}}).then(function(response) {
    //code here 
    
   alert("Patient Added");     
   ll.handleReset()
    })
    .catch(function (error) {
      // handle error
      alert(error)
    })
    .then(function () {
      // always executed
    });
     }
     else
     {
      alert('Invalid Data Entry')
     }


    }


  render() {
    return (
      <div>
      <h1>Patient Registration</h1>
      <Form >
        <FormGroup> 
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>Patient Name</InputGroupText>
            </InputGroupAddon>
             <Input onChange={this.handlepname} value={this.state.pname}/>
             
             
          </InputGroup>
          
          </FormGroup> 
          
          <FormGroup>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>Patient Number</InputGroupText>
            </InputGroupAddon>
             <Input onChange={this.handlepno} value={this.state.pno} disabled/>
          </InputGroup>
          </FormGroup>
          <FormGroup>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>Mobile No.</InputGroupText>
            </InputGroupAddon>
             <Input onChange={this.handlemobno} value={this.state.mobno}/>
          </InputGroup>
          </FormGroup>
          <FormGroup>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>Email</InputGroupText>
            </InputGroupAddon>
             <Input onChange={this.handleemail} value={this.state.email} type="email"/>
          </InputGroup>
          </FormGroup>
          <FormGroup>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>NHIF Number</InputGroupText>
            </InputGroupAddon>
             <Input onChange={this.handlenhifno} value={this.state.nhifno} type="text"/>
          </InputGroup>
        </FormGroup> 
        <FormGroup>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>ID Number</InputGroupText>
            </InputGroupAddon>
             <Input onChange={this.handleidno} value={this.state.idno} type="text"/>
          </InputGroup>
          </FormGroup>
          
        <br/>  
        
        <Row>
        <Col ><Button color="primary" size="lg" type="button" onClick={this.handleSave} >Add Patient</Button></Col>
        <Col ><Button color="primary" size="lg" type="button"  onClick={this.toggle}>Find Patient</Button></Col>
        
      </Row>

      </Form>
      <Modal isOpen={this.state.modal} toggle={this.toggle} >
        <ModalHeader toggle={this.toggle}>Find Patient</ModalHeader>
        <ModalBody>
        <FormGroup>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>Patient Name</InputGroupText>
            </InputGroupAddon>
             <Input onChange={this.handlefitlter} type="text"/>
          </InputGroup>
          </FormGroup>
           {this.state.pselect && <FormGroup>
       <Input type="select" name="selectMulti" id="exampleSelectMulti" onClick={this.handleNlist} multiple>
       {this.state.nlist.map(item => (
          <option>{item.pname}</option>
          ))}
        </Input>
      </FormGroup> }
          </ModalBody>
        <ModalFooter>
          
        </ModalFooter>
      </Modal>
      
     
     </div>
    );
  }
}
export default Patientreg 