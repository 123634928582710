import React, { useState, useEffect} from 'react';
import ReactDOM from 'react-dom';

import './css/w3.css';
import './css/nav.css'

import Productreg from './prodreg'
import Stockcontrol from './stockcontrol'
import Stockcontrollist from './stockcontrollist'
import Sales from './sales'
import Userreg from './userreg'
import Manageuser from './manageruser'
import Saleslist from './saleslist'
import Patientreg from './patientreg' 
import Bookpatient from './bookpatient'
import Paymentlist from './payments'
import Outpatientlist from './outpatientlist'
import Lablist from './lablist'

import { Button, Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Dropdown  } from 'reactstrap';


const Menu = (props) => {

  const [collapsed, setCollapsed] = useState(true);
  const [showmenu, setshowmenu] = useState(false);

  const toggleNavbar = () => setCollapsed(!collapsed);
  const toggleNavbar1 = () => setCollapsed(!collapsed);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownOpen1, setDropdownOpen1] = useState(false);
  const [dropdownOpen2, setDropdownOpen2] = useState(false);
  const [dropdownOpen3, setDropdownOpen3] = useState(false);
  const [dropdownOpen4, setDropdownOpen4] = useState(false);
  const [dropdownOpen5, setDropdownOpen5] = useState(false);
  const [dropdownOpen6, setDropdownOpen6] = useState(false);
  const [admin, setAdmin] = useState(false);
  const [pharmacy, setPharmacy] = useState(false);
  const [lab, setLab] = useState(false);
  const [doctor, setDoctor] = useState(false);
  const [report, setReport] = useState(false);
  const [accounts, setAccounts] = useState(false);
  const [reception, setReception] = useState(false);

  const toggle = () => setDropdownOpen(prevState => !prevState);
  const toggle1 = () => setDropdownOpen1(prevState => !prevState);
  const toggle2 = () => setDropdownOpen2(prevState => !prevState);
  const toggle3 = () => setDropdownOpen3(prevState => !prevState);
  const toggle4 = () => setDropdownOpen4(prevState => !prevState);
  const toggle5 = () => setDropdownOpen5(prevState => !prevState);
  const toggle6 = () => setDropdownOpen6(prevState => !prevState);
  const [lastClicked, setLastClicked] = useState(null);
  
  useEffect(() => {
  switch(props.groupn){
    case "ADMIN":
      setAdmin(true)
      setPharmacy(true)
      setLab(true)
      setDoctor(true)
      setReport(true)
      setAccounts(true)
      setReception(true)
      break;
    case "PHARMACY":
      setPharmacy(true)
      break
    case "RECEPTION":
      setReception(true)
      break

  }
})


const message1 = (e,ss) => {
e.preventDefault();
 alert(ss);
}
 
 const handlelogout = () => {  
    window.location.reload();
    

     }

const handleClick = (e,step) => { 


  switch(step){
    case 1:
      
      ReactDOM.render(
      <React.StrictMode>
        <Userreg />
      </React.StrictMode>,
      document.getElementById('mbody')
    );
      break;
    case 2:

    ReactDOM.render(
      <React.StrictMode>
        <Manageuser />
      </React.StrictMode>,
      document.getElementById('mbody')
    );
     
      break;
    case 3:
      
      break;
    case 4:
      
      break;
      
    case 5:
        ReactDOM.render(
      <React.StrictMode>
        <Productreg />
      </React.StrictMode>,
      document.getElementById('mbody')
    );
         
      break;
      
    case 6:
          ReactDOM.render(
      <React.StrictMode>
        <Stockcontrol />
      </React.StrictMode>,
      document.getElementById('mbody')
    );
     
      break;
      
    case 7:
      ReactDOM.render(
      <React.StrictMode>
        <Sales />
      </React.StrictMode>,
      document.getElementById('mbody')
    );
      break;
    case 8:
      
      ReactDOM.render(
      <React.StrictMode>
        <Stockcontrollist />
      </React.StrictMode>,
      document.getElementById('mbody')
    );
      break;
    case 9:
      
      ReactDOM.render(
      <React.StrictMode>
        <Lablist />
      </React.StrictMode>,
      document.getElementById('mbody')
    );
      break;
    case 12:
      ReactDOM.render(
      <React.StrictMode>
        <Outpatientlist />
      </React.StrictMode>,
      document.getElementById('mbody')
    );
      break;


    case 23:
    ReactDOM.render(
      <React.StrictMode>
        <Saleslist/>
      </React.StrictMode>,
      document.getElementById('mbody')
    );

      break;

    case 27:
      ReactDOM.render(
      <React.StrictMode>
        <Patientreg/>
      </React.StrictMode>,
      document.getElementById('mbody')
    );

      break;

    case 28:

      ReactDOM.render(
      <React.StrictMode>
        <Bookpatient/>
      </React.StrictMode>,
      document.getElementById('mbody')
    );

      break;
    case 29:
          ReactDOM.render(
      <React.StrictMode>
        <Paymentlist />
      </React.StrictMode>,
      document.getElementById('mbody')
    );
     
      break;

  }
    
   }


  return (
      <Navbar    dark expand="md">
        
        <NavbarToggler onClick={toggleNavbar} className="mr-2 " style={{ backgroundColor: '#378248' }} />
    
        <Collapse isOpen={!collapsed} navbar>
          <Nav navbar>

           
     {admin && <Dropdown isOpen={dropdownOpen2} toggle={toggle2}>
      <DropdownToggle nav  caret className="d-flex align-items-center font-weight-bold">
        Admin 
      </DropdownToggle>
      <DropdownMenu>
        
        <DropdownItem onClick={e=>handleClick(e,1)}>Register User </DropdownItem>
        <DropdownItem onClick={e=>handleClick(e,2)}>Manage</DropdownItem>
        <DropdownItem onClick={e=>handleClick(e,3)}>Logs</DropdownItem>
        <DropdownItem onClick={e=>handleClick(e,4)}>Audit</DropdownItem>
    
      </DropdownMenu>
    </Dropdown>}
    {reception && <Dropdown isOpen={dropdownOpen6} toggle={toggle6}>
      <DropdownToggle nav  caret className="d-flex align-items-center font-weight-bold">
        Reception
      </DropdownToggle>
      <DropdownMenu>
        
        <DropdownItem onClick={e=>handleClick(e,27)}>Register Patient </DropdownItem>
        <DropdownItem onClick={e=>handleClick(e,28)}>Book Patient</DropdownItem>
        <DropdownItem onClick={e=>handleClick(e,30)}>Admit Patient</DropdownItem>
        
    
      </DropdownMenu>
    </Dropdown>
  }
      
     {pharmacy && <Dropdown isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle nav  caret className="d-flex align-items-center font-weight-bold">
        Pharmacy
      </DropdownToggle>
      <DropdownMenu>
        
        <DropdownItem onClick={e=>handleClick(e,5)}>Register Drug </DropdownItem>
        <DropdownItem onClick={e=>handleClick(e,6)}>Stock Control</DropdownItem>
        <DropdownItem onClick={e=>handleClick(e,7)}>Sales</DropdownItem>
        <DropdownItem onClick={e=>handleClick(e,8)}>Stock Balance</DropdownItem>
        
    
      </DropdownMenu>
    </Dropdown>
  }
    {lab && <Dropdown isOpen={dropdownOpen1} toggle={toggle1}>
      <DropdownToggle nav  caret className="d-flex align-items-center font-weight-bold ">
        Labaratory
      </DropdownToggle>
      <DropdownMenu>
        
        <DropdownItem onClick={e=>handleClick(e,9)}>Tests </DropdownItem>
        <DropdownItem onClick={e=>handleClick(e,10)}>Stock Control</DropdownItem>
        <DropdownItem onClick={e=>handleClick(e,11)}>Stock Balance</DropdownItem>
    
      </DropdownMenu>
    </Dropdown>}
   {doctor && <Dropdown isOpen={dropdownOpen3} toggle={toggle3}>
      <DropdownToggle nav  caret className="d-flex align-items-center font-weight-bold ">
        Doctor
      </DropdownToggle>
      <DropdownMenu>
        
        <DropdownItem onClick={e=>handleClick(e,12)}>Out Patient</DropdownItem>
        <DropdownItem onClick={e=>handleClick(e,30)}>In Patient</DropdownItem>
        <DropdownItem onClick={e=>handleClick(e,13)}>Prescription</DropdownItem>
        <DropdownItem onClick={e=>handleClick(e,14)}>Status</DropdownItem>
        <DropdownItem onClick={e=>handleClick(e,15)}>Admision</DropdownItem>
    
      </DropdownMenu>
    </Dropdown>}
   {accounts && <Dropdown isOpen={dropdownOpen4} toggle={toggle4}>
      <DropdownToggle nav  caret className="d-flex align-items-center font-weight-bold ">
        Accounts
      </DropdownToggle>
      <DropdownMenu>
        
        <DropdownItem onClick={e=>handleClick(e,16)}>Expenditure</DropdownItem>
        <DropdownItem onClick={e=>handleClick(e,17)}>Income</DropdownItem>
        <DropdownItem onClick={e=>handleClick(e,18)}>Payroll</DropdownItem>
        <DropdownItem onClick={e=>handleClick(e,19)}>Banking</DropdownItem>
        <DropdownItem onClick={e=>handleClick(e,20)}>Trial Balance</DropdownItem>
        <DropdownItem onClick={e=>handleClick(e,21)}>Assets</DropdownItem>
    
      </DropdownMenu>
    </Dropdown> }

   {report && <Dropdown isOpen={dropdownOpen5} toggle={toggle5}>
      <DropdownToggle nav  caret className="d-flex align-items-center font-weight-bold ">
        

        Reports
      </DropdownToggle>
      <DropdownMenu>
        
        <DropdownItem onClick={e=>handleClick(e,22)}>Consultancy</DropdownItem>
        <DropdownItem onClick={e=>handleClick(e,23)}>Pharmacy Sales</DropdownItem>
        <DropdownItem onClick={e=>handleClick(e,24)}>Lab Report</DropdownItem>
        <DropdownItem onClick={e=>handleClick(e,25)}>Inpatient</DropdownItem>
        <DropdownItem onClick={e=>handleClick(e,26)}>Stock</DropdownItem>
        <DropdownItem onClick={e=>handleClick(e,29)}>Payments</DropdownItem>
    
      </DropdownMenu>
    </Dropdown>}
     <NavItem className="d-md-block ml-md-auto">
      <Button
        outline
        
        className="d-flex align-items-center font-weight-bold"
        onClick={e=>handlelogout()}
        
      >
        <span className="fa fa-sign-in fa-lg"></span> Logout
      </Button>
    </NavItem>


          </Nav>
        </Collapse>
      </Navbar>
   
  );
}

export default Menu;
